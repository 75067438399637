import React, { useState } from 'react';
import { isEmpty } from 'ramda';
import { BiCheck } from 'react-icons/bi';

import SolidButton from 'components/SolidButton';
import SendMessageModal from 'components/Patients/SendMessageModal';

import { useToggle } from 'utils/hooks/useToggle';

import maleAvatar from 'assets/images/patient-male.svg';
import femaleAvatar from 'assets/images/patient-female.svg';

import './patientsList.scss';

const PatientsList = (props) => {
  const { memoInitials, patients, selectedId, paramsId, onSelectPatient, patientsView } = { ...props };
  const [modalIsOpen, toggleModal] = useToggle();
  const [selectedPatients, setSelectedPatients] = useState([]);

  const handleSelectPatients = (patientId) => {
    setSelectedPatients((prevSelected) => {
      const isSelected = prevSelected.find((item) => item === patientId);
      if (isSelected) {
        return prevSelected.filter((item) => item !== patientId);
      }
      return [...prevSelected, patientId];
    });
  };

  const handleUnselectAll = () => {
    setSelectedPatients([]);
  };

  const sortedPatients = (a, b) => {
    const { name: nameA = '', email: emailA = '' } = { ...a };
    const { name: nameB = '', email: emailB = '' } = { ...b };
    const toLowerA = (nameA || emailA).toLowerCase();
    const toLowerB = (nameB || emailB).toLowerCase();

    if (toLowerA > toLowerB) return 1;
    if (toLowerA < toLowerB) return -1;
    return 0;
  };

  const getFirstName = (patientName) => (patientName ? patientName.slice(0, patientName.indexOf(' ')) : null);

  const getInitials = (patientName, patientEmail) => (patientName || patientEmail).slice(0, 2).toUpperCase();

  return (
    <>
      {!isEmpty(selectedPatients) && !patientsView && (
        <SolidButton onClick={toggleModal} className="slim send-button">
          Enviar mensaje
        </SolidButton>
      )}
      {memoInitials.map((letter) => {
        const patientsPerLetter = (patients || [])
          .filter(({ name, email }) => (name || email).toUpperCase().indexOf(letter) === 0)
          .sort(sortedPatients);
        return (
          <div key={letter} className="letter-container">
            {!isEmpty(patientsPerLetter) && <p className="letter">{letter}</p>}
            {patientsPerLetter.map((patient) => {
              const { name = '', gender = '', cardId = '', email = '', id = '', status = '' } = { ...patient };
              const isSelected = (paramsId || selectedId) === id;
              const isSelectedToSendEmail = [...selectedPatients].indexOf(id) !== -1;

              return (
                <div key={`${id}-${cardId}`} className="container-patient">
                  {!patientsView ? (
                    <div
                      aria-hidden="true"
                      className={`letter-container__patient ${isSelected ? 'selected' : ''} ${status}`}
                      onClick={() => onSelectPatient(isSelected, { patient })}
                    >
                      <div className="avatar">
                        <img src={gender === 'Hombre' ? maleAvatar : femaleAvatar} alt={name} />
                      </div>
                      <div className="data">
                        <p className={`${isSelected ? 'data__selected' : ''}`}>{name}</p>
                        <p className={`${isSelected ? 'data__selected' : ''}`}>{cardId}</p>
                        <p className={`${isSelected ? 'data__selected' : ''}`}>{email}</p>
                      </div>
                    </div>
                  ) : (
                    <div
                      aria-hidden="true"
                      className={`letter-container__view ${isSelected ? 'selected' : ''} ${status}`}
                      onClick={() => onSelectPatient(isSelected, { patient })}
                    >
                      <div className="initials">
                        <p className="initials__init">{getInitials(name, email)}</p>
                      </div>
                      <p className={`name name--${isSelected ? 'selected' : ''}`}>{getFirstName(name)}</p>
                    </div>
                  )}
                  {!patientsView && (
                    <div
                      aria-hidden="true"
                      onClick={() => handleSelectPatients(id)}
                      className={`container-patient__checkbox container-patient__checkbox--${
                        isSelectedToSendEmail ? 'selected' : ''
                      }`}
                    >
                      {isSelectedToSendEmail && <BiCheck />}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
      <SendMessageModal
        modalIsOpen={modalIsOpen}
        onCloseModal={toggleModal}
        selectedPatients={selectedPatients}
        unselectPatients={handleUnselectAll}
      />
    </>
  );
};

export default PatientsList;
