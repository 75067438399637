/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { FaStar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'swiper/css';

import painShape from 'assets/images/pelvic-shape.webp';
import sittingShape from 'assets/images/sitting.webp';
import smile from 'assets/images/smile.svg';
import checkMark from 'assets/images/check-mark.svg';
import strawberry from 'assets/images/strawberry.svg';
import bodyShape from 'assets/images/body-shape.svg';
import plant from 'assets/images/plant.svg';
import heart from 'assets/images/multi-heart.svg';
import linearGradientBg from 'assets/images/linear-gradient-bg.webp';
import luisa from 'assets/images/caro.webp';
import pao from 'assets/images/pao.webp';
import anna from 'assets/images/anna.webp';
import { useGetParams } from '../utils/useGetParams';

import PerkScreen from '../PerkScreen';
import InfoScreen from '../InfoScreen';
import Offerings from '../Offerings';
// import TrialScreen from '../TrialScreen';

const PainPlan = ({ offerings, selectedOffer, setSelectedOffer }) => {
  const { t } = useTranslation();
  const { planStep } = useGetParams();

  return (
    <>
      {planStep === 1 && (
        <PerkScreen>
          <LazyLoadImage effect="opacity" src={sittingShape} alt="sitting woman" className="info-screen__bgimg perks" />
          <section className="info-screen__footer perks">
            <div className="info-screen__footer_topbg plan" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon perks">
              <img src={painShape} alt="silhouette of sitting woman" />
            </div>
            <h2 className="info-screen__footer_title">{t('evieOnboarding.perkScreen.title3')}</h2>
            <p className="info-screen__footer_content perks">{t('evieOnboarding.perkScreen.description3')}</p>
            <div className="perk-screen__perkcard">
              <img className="perk-screen__perkcard_icon" src={smile} alt="smily" />
              <p className="perk-screen__perkcard_content">{t('evieOnboarding.perkScreen.perks.physio')}</p>
            </div>
            <div className="perk-screen__perkcard">
              <img className="perk-screen__perkcard_icon" src={checkMark} alt="checkmark" />
              <p className="perk-screen__perkcard_content">{t('evieOnboarding.perkScreen.perks.chat')}</p>
            </div>
            <div className="perk-screen__perkcard">
              <img className="perk-screen__perkcard_icon" src={strawberry} alt="strawberry" />
              <p className="perk-screen__perkcard_content">{t('evieOnboarding.perkScreen.perks.exercises')}</p>
            </div>
            <div className="perk-screen__perkcard">
              <img className="perk-screen__perkcard_icon" src={bodyShape} alt="body shape" />
              <p className="perk-screen__perkcard_content">{t('evieOnboarding.perkScreen.perks.routines')}</p>
            </div>
            <div className="perk-screen__perkcard">
              <img className="perk-screen__perkcard_icon" src={plant} alt="plant" />
              <p className="perk-screen__perkcard_content">{t('evieOnboarding.perkScreen.perks.audio')}</p>
            </div>
          </section>
        </PerkScreen>
      )}
      {/* {planStep === 2 && <TrialScreen />} */}
      {planStep === 2 && (
        <InfoScreen>
          <Swiper speed={600} slidesPerView={1} autoplay={{ delay: 4000 }} modules={[Autoplay]} loop>
            <SwiperSlide>
              <section className="info-screen__testimonials">
                <div className="info-screen__testimonial">
                  <div>
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                  </div>
                  <p className="info-screen__testimonial_content">
                    {t('evieOnboarding.planScreen.testimonials.pain.luisa.content')}
                  </p>
                  <p className="info-screen__testimonial_author">
                    {t('evieOnboarding.planScreen.testimonials.pain.luisa.author')}
                  </p>
                </div>
                <img
                  className="info-screen__bgimg testimonialbg"
                  src={linearGradientBg}
                  alt="linear gradient background"
                />
                <img className="info-screen__bgimg info-screen__testimonials_img" src={luisa} alt="Woman" />
              </section>
            </SwiperSlide>
            <SwiperSlide>
              <section className="info-screen__testimonials">
                <div className="info-screen__testimonial">
                  <div>
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                  </div>
                  <p className="info-screen__testimonial_content">
                    {t('evieOnboarding.planScreen.testimonials.pain.pao.content')}
                  </p>
                  <p className="info-screen__testimonial_author">
                    {t('evieOnboarding.planScreen.testimonials.pain.pao.author')}
                  </p>
                </div>
                <img
                  className="info-screen__bgimg testimonialbg"
                  src={linearGradientBg}
                  alt="linear gradient background"
                />
                <img className="info-screen__bgimg info-screen__testimonials_img" src={pao} alt="Woman" />
              </section>
            </SwiperSlide>
            <SwiperSlide>
              <section className="info-screen__testimonials">
                <div className="info-screen__testimonial">
                  <div>
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                  </div>
                  <p className="info-screen__testimonial_content">
                    {t('evieOnboarding.planScreen.testimonials.pain.anna.content')}
                  </p>
                  <p className="info-screen__testimonial_author">
                    {t('evieOnboarding.planScreen.testimonials.pain.anna.author')}
                  </p>
                </div>
                <img
                  className="info-screen__bgimg testimonialbg"
                  src={linearGradientBg}
                  alt="linear gradient background"
                />
                <img className="info-screen__bgimg info-screen__testimonials_img" src={anna} alt="Woman" />
              </section>
            </SwiperSlide>
          </Swiper>
          <section className="info-screen__footer">
            <div className="info-screen__footer_topbg" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon">
              <img className="multi-heart-icon" src={heart} alt="heart" />
            </div>
            <h2 className="info-screen__footer_title plan-screen">{t('evieOnboarding.planScreen.title')}</h2>
            <Offerings offerings={offerings} selectedOffer={selectedOffer} setSelectedOffer={setSelectedOffer} />
          </section>
        </InfoScreen>
      )}
    </>
  );
};

PainPlan.defaultProps = {
  selectedOffer: null,
};

PainPlan.propTypes = {
  offerings: PropTypes.arrayOf(PropTypes.shape({ identifier: PropTypes.string.isRequired })).isRequired,
  selectedOffer: PropTypes.shape({ identifier: PropTypes.string.isRequired }),
  setSelectedOffer: PropTypes.func.isRequired,
};

export default PainPlan;
