import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: 440,
    padding: 24,
    borderRadius: 16,
  },
};

const FollowupQuestion = ({ modalIsOpen, toggleModal, handleFollowupQuestion }) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      modalProps={modalProps}
      modalIsOpen={modalIsOpen}
      closeModal={toggleModal}
      closeable={false}
    >
      FollowupQuestion
      <div>
        <SolidButton onClick={handleFollowupQuestion}>no</SolidButton>
        <SolidButton onClick={handleFollowupQuestion}>yes</SolidButton>
      </div>
    </Modal>
  );
};

FollowupQuestion.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleFollowupQuestion: PropTypes.func.isRequired,
};

export default FollowupQuestion;
