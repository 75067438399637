import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { path, propOr, isEmpty } from 'ramda';
import Loader from 'react-loader-spinner';
import { FaUser } from 'react-icons/fa';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

import { GET_BOOKINGS, GET_INDIVIDUALS } from 'apollo/queries';
import { useAuthQuery, useFormInput, useFilterTabs } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { useFilterByProperty } from 'utils/hooks/useFilterByProperty';
import { useToggle } from 'utils/hooks/useToggle';
import { queryParams } from 'utils/string';
import { handleFilterTabs } from 'utils/handleFilterTabs';
import { ROLES, ROUTES, FETCH_BY_ROLE, GET_PARTNER_BY_ROLE } from 'utils/constants';
import { getUserRole } from 'utils/getUserRole';
import { LEFT_MENU } from 'utils/leftMenu';

// import { useAppState } from 'components/StoreProvider';
import Header from 'components/Header';
import SearchFilter from 'components/SearchFilter';
import PatientsList from 'components/Patients/PatientsList';
import Patient from 'components/Patients/Patient';
import UnselectedPatient from 'components/Patients/UnselectedPatient';
import FilterTabs from 'components/Employees/FilterTabs';
import { NoPatientsFound, NoMembers } from 'components/EmptyState';

import noPatients from 'assets/images/no-patients.svg';

import './individuals.scss';

const Individuals = (props) => {
  useRedirectByRole([ROLES.CLINIC, ROLES.SUPER_CLINIC, ROLES.SPECIALIST]);
  const { userRole, isSuperClinic } = getUserRole();
  const { t } = useTranslation();
  const filters = [t('tabs.active'), t('tabs.inactive'), t('tabs.all')];
  const searchParams = path(['history', 'location', 'search'])(props);
  const params = { ...queryParams(searchParams) };
  const { tab: targetTab = '0' } = { ...params };
  const { id } = useParams();
  const changeRoute = path(['history', 'push'])(props);

  const { data: dataProfile } = useAuthQuery(FETCH_BY_ROLE[userRole]);
  const partner = propOr({}, GET_PARTNER_BY_ROLE[userRole])(dataProfile);
  const { profile = {} } = { ...partner };
  const { hasCalls = false } = { ...profile };

  const { loading, data, refetch } = useAuthQuery(GET_INDIVIDUALS);
  const allPatients = propOr([], 'individuals')(data);

  const { data: dataBookings = {}, refetch: refetchBookings = () => {} } = isSuperClinic
    ? {}
    : useAuthQuery(GET_BOOKINGS);
  const { clinicDashboard = {} } = { ...dataBookings };
  const { patientStatus = {} } = { ...clinicDashboard };
  const { current_active: activePatients = 0, current_available: availablePatients = 0 } = { ...patientStatus };
  const limited = activePatients >= availablePatients;

  const [selected, setSelected] = useState({});
  const [patients, setPatients] = useState([]);
  const [patientsView, setPatientsView] = useToggle();

  // const { toggleInviteCreateMember = () => {} } = useAppState();
  const searchPatients = useFormInput('');
  const { value = '' } = { ...searchPatients };
  const { handleChangeFilter, filterTab } = useFilterTabs(filters);
  const allActivePatients = useFilterByProperty({ data: allPatients, property: 'status', value: 'active' });
  const allUnactivePatients = useFilterByProperty({ data: allPatients, property: 'status', value: 'unactive' });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(patients) || !isEmpty(allPatients)) {
      setPatients(allPatients);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPatients]);

  const filterUsers = (user) => {
    const { name, cardId, email, covenant } = { ...user };
    const { name: covenantName = '' } = { ...covenant };
    const patient = `${name || ''}-${cardId || ''}-${email}-${covenantName}`.toLowerCase();
    return patient.indexOf(`${value}`.toLowerCase()) !== -1;
  };

  useEffect(() => {
    let temporal = handleFilterTabs(filterTab, {
      activeItems: allActivePatients,
      unactiveItems: allUnactivePatients,
      allItems: allPatients,
      filterItems: filters,
    });
    if (value) {
      temporal = handleFilterTabs(filterTab, {
        activeItems: allActivePatients,
        unactiveItems: allUnactivePatients,
        allItems: allPatients,
        filterItems: filters,
      }).filter(filterUsers);
    }
    setPatients(temporal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPatients, value, filterTab]);

  const memoInitials = useMemo(() => {
    let alls = (patients || []).reduce((a, b) => {
      const { name = '', email = '' } = { ...b };
      return [...a, (name || email).slice(0, 1).toUpperCase()];
    }, []);
    alls = [...new Set(alls)].sort();
    return alls;
  }, [patients]);

  const { patient: selectedPatient = {} } = { ...selected };
  const { id: selectedId = '' } = { ...selectedPatient };

  const onSelectPatient = (isSelected, { patient }) => {
    const { id: selectedPatientId } = { ...patient };
    if (isSelected) {
      changeRoute(ROUTES.INDIVIDUALS);
      setSelected({});
    } else {
      changeRoute(`${ROUTES.INDIVIDUALS}/${selectedPatientId}`);
      setSelected({ patient });
    }
  };

  return (
    <div className="d-flex">
      {LEFT_MENU[userRole]}
      <div className="dashboard__main">
        <Header title="Individuals" shadow />
        {loading && (
          <div className="loading-container">
            <Loader type="ThreeDots" color="#495fd7" height={100} width={100} visible={loading} />
          </div>
        )}
        {!loading && isEmpty(allPatients) && (
          <NoMembers title="¡Aún no hay individuals registrados!" image={noPatients} />
        )}
        {!loading && !isEmpty(allPatients) && (
          <div className="patients-view">
            <div className={`patients-container patients-container--${patientsView ? 'collapsed' : ''}`}>
              <button type="button" className="arrow-container" onClick={setPatientsView}>
                {patientsView ? <IoIosArrowForward /> : <IoIosArrowBack />}
              </button>
              <SearchFilter
                onClick={patientsView ? setPatientsView : () => {}}
                searchItems={searchPatients}
                placeholder="Buscar un paciente"
                patientsView={patientsView}
                collapsed={patientsView ? 'collapsed' : ''}
                widthModifier="patients"
              />
              {!patientsView && (
                <FilterTabs filters={filters} filterTab={filterTab} handleChangeFilter={handleChangeFilter} />
              )}
              <div
                className={`current-status current-status--${patientsView ? 'collapsed' : ''} ${
                  limited && !isSuperClinic ? 'limit' : ''
                }`}
              >
                <div className="current-status__text">
                  <div className={`current-status__text_user ${limited && !isSuperClinic ? 'limit__user' : ''}`}>
                    <FaUser />
                  </div>
                  {!patientsView && (
                    <p className={`added ${limited && !isSuperClinic ? 'limit-added' : ''}`}>Pacientes agregados</p>
                  )}
                </div>
                <p className={`current-status_number ${limited && !isSuperClinic ? 'limit-number' : ''}`}>
                  {isSuperClinic ? `${allPatients.length}` : `${activePatients}/${availablePatients}`}
                </p>
              </div>
              <div className={`patients-list patients-list--${patientsView ? 'collapsed' : ''}`}>
                <PatientsList
                  memoInitials={memoInitials}
                  patients={patients}
                  selectedId={selectedId}
                  paramsId={id}
                  onSelectPatient={onSelectPatient}
                  patientsView={patientsView}
                />
                {!loading && !patientsView && isEmpty(patients) && <NoPatientsFound />}
              </div>
            </div>
            <div className={`selected-patient selected-patient--${patientsView ? 'collapsed' : ''}`}>
              {!!selectedId || !!id ? (
                <Patient
                  targetTab={targetTab}
                  changeRoute={changeRoute}
                  selectedId={selectedId}
                  paramsId={id}
                  onUpdateCurrentActive={refetchBookings}
                  hasCalls={hasCalls}
                  isIndividual
                />
              ) : (
                <UnselectedPatient />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Individuals;
